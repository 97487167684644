@media (max-width: 768px) {
    .mat-mdc-table[matTableResponsive] {
      border-spacing: 2px;
      
      .table-primary th{
        background-color: $color-primary;
        border: 1px solid $color-primary;
        color: $bgcolor ;      
      }
      .table-primary .mat-mdc-row:hover .mat-mdc-cell {
        border-color: $color-alert;
      }
      .table-primary td {
        padding: 0 2px 0 2px!important;
      }
      .table-primary td button {
        padding: 0 2px 0 2px!important;
      }
      .table-primary td button:hover {
        color: $color-accent;
      }
      .table-primary td button mat-icon:hover {
        color: $color-accent;
      }

      .mat-mdc-header-row {
        display: flex;
        flex-wrap: wrap;
        height: auto;
        position: sticky;
        top: 0;
        z-index: 2;
  
        th.mat-mdc-header-cell {
          display: flex;
          flex: 1;
          align-items: center;
          padding: 1rem 0.5rem;
          justify-content: space-around;
  
          & > .mat-sort-header-container {
            padding-left: 15px; // padding is used to center the header table cells, due to the width of the sort icon
          }
  
          &:not([mat-sort-header]),
          &.mat-sort-header-disabled,
          &.mat-column-actions {
            display: none;
          }
        }
      }
  
      .mat-mdc-row {
        height: auto;
  
        td.mat-mdc-cell:not(.mat-column-actions) {
          display: grid;
          grid-template-columns: 50% 50%;
          padding: 0.5rem;          
          word-break: break-word;
  
          & > * {
            grid-column: 2;
          }
  
          &:before {
            content: attr(data-column-name);
            text-align: left;
          }
        }
  
        td.mat-column-actions {
          display: flex;
          justify-content: center;
          padding: 0 0.5rem;
  
          & > button {
            margin: 0.5rem 0;
          }
        }
  
        &:last-of-type td:last-of-type {
          border: none;
        }
  
        &:not(:last-of-type) td:last-of-type {
          border-bottom: 0.5rem solid #fafafa;
        }
      }
    }
  
    .mat-mdc-card,
    .mat-expansion-panel {
      .mat-mdc-table[matTableResponsive] {
        border: 0.5rem solid #fafafa;
      }
    }
  }
  

  