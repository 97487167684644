@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap");
.mdc-floating-label {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  position: absolute;
  /* @noflip */
  left: 0;
  /* @noflip */
  -webkit-transform-origin: left top;
  /* @noflip */
  transform-origin: left top;
  line-height: 1.15rem;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: text;
  overflow: hidden;
  /* @alternate */
  will-change: transform;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
[dir=rtl] .mdc-floating-label, .mdc-floating-label[dir=rtl] {
  /* @noflip */
  right: 0;
  /* @noflip */
  left: auto;
  /* @noflip */
  -webkit-transform-origin: right top;
  /* @noflip */
  transform-origin: right top;
  /* @noflip */
  text-align: right;
}

.mdc-floating-label--float-above {
  cursor: auto;
}

.mdc-floating-label--required::after {
  /* @noflip */
  margin-left: 1px;
  /* @noflip */
  margin-right: 0px;
  content: "*";
}
[dir=rtl] .mdc-floating-label--required::after, .mdc-floating-label--required[dir=rtl]::after {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 1px;
}

.mdc-floating-label--float-above {
  transform: translateY(-106%) scale(0.75);
}

.mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-standard 250ms 1;
}

@keyframes mdc-floating-label-shake-float-above-standard {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-106%) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 0%)) translateY(-106%) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 0%)) translateY(-106%) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-106%) scale(0.75);
  }
}
.mdc-line-ripple::before, .mdc-line-ripple::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom-style: solid;
  content: "";
}
.mdc-line-ripple::before {
  border-bottom-width: 1px;
  z-index: 1;
}
.mdc-line-ripple::after {
  transform: scaleX(0);
  border-bottom-width: 2px;
  opacity: 0;
  z-index: 2;
}
.mdc-line-ripple::after {
  transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1), opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-line-ripple--active::after {
  transform: scaleX(1);
  opacity: 1;
}

.mdc-line-ripple--deactivating::after {
  opacity: 0;
}

.mdc-notched-outline {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  height: 100%;
  /* @noflip */
  text-align: left;
  pointer-events: none;
}
[dir=rtl] .mdc-notched-outline, .mdc-notched-outline[dir=rtl] {
  /* @noflip */
  text-align: right;
}
.mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing {
  box-sizing: border-box;
  height: 100%;
  border-top: 1px solid;
  border-bottom: 1px solid;
  pointer-events: none;
}
.mdc-notched-outline__leading {
  /* @noflip */
  border-left: 1px solid;
  /* @noflip */
  border-right: none;
  width: 12px;
}
[dir=rtl] .mdc-notched-outline__leading, .mdc-notched-outline__leading[dir=rtl] {
  /* @noflip */
  border-left: none;
  /* @noflip */
  border-right: 1px solid;
}
.mdc-notched-outline__trailing {
  /* @noflip */
  border-left: none;
  /* @noflip */
  border-right: 1px solid;
  flex-grow: 1;
}
[dir=rtl] .mdc-notched-outline__trailing, .mdc-notched-outline__trailing[dir=rtl] {
  /* @noflip */
  border-left: 1px solid;
  /* @noflip */
  border-right: none;
}
.mdc-notched-outline__notch {
  flex: 0 0 auto;
  width: auto;
  max-width: calc(100% - 12px * 2);
}
.mdc-notched-outline .mdc-floating-label {
  display: inline-block;
  position: relative;
  max-width: 100%;
}
.mdc-notched-outline .mdc-floating-label--float-above {
  text-overflow: clip;
}
.mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  max-width: 133.3333333333%;
}

.mdc-notched-outline--notched .mdc-notched-outline__notch {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 8px;
  border-top: none;
}
[dir=rtl] .mdc-notched-outline--notched .mdc-notched-outline__notch, .mdc-notched-outline--notched .mdc-notched-outline__notch[dir=rtl] {
  /* @noflip */
  padding-left: 8px;
  /* @noflip */
  padding-right: 0;
}

.mdc-notched-outline--no-label .mdc-notched-outline__notch {
  display: none;
}

:root {
  --primary: #273a82;
}

@font-face {
  font-family: "Avenir";
  src: local("./assets/fonts/Avenir Roman"), local("Avenir-Roman"), url("./assets/fonts/Avenir-Roman.woff2") format("woff2"), url("./assets/fonts/Avenir-Roman.woff") format("woff"), url("./assets/fonts/Avenir-Roman.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@media (max-width: 768px) {
  .mat-mdc-table[matTableResponsive] {
    border-spacing: 2px;
  }
  .mat-mdc-table[matTableResponsive] .table-primary th {
    background-color: #273a82;
    border: 1px solid #273a82;
    color: rgb(232, 232, 232);
  }
  .mat-mdc-table[matTableResponsive] .table-primary .mat-mdc-row:hover .mat-mdc-cell {
    border-color: #e48213;
  }
  .mat-mdc-table[matTableResponsive] .table-primary td {
    padding: 0 2px 0 2px !important;
  }
  .mat-mdc-table[matTableResponsive] .table-primary td button {
    padding: 0 2px 0 2px !important;
  }
  .mat-mdc-table[matTableResponsive] .table-primary td button:hover {
    color: #FF4081;
  }
  .mat-mdc-table[matTableResponsive] .table-primary td button mat-icon:hover {
    color: #FF4081;
  }
  .mat-mdc-table[matTableResponsive] .mat-mdc-header-row {
    display: flex;
    flex-wrap: wrap;
    height: auto;
    position: sticky;
    top: 0;
    z-index: 2;
  }
  .mat-mdc-table[matTableResponsive] .mat-mdc-header-row th.mat-mdc-header-cell {
    display: flex;
    flex: 1;
    align-items: center;
    padding: 1rem 0.5rem;
    justify-content: space-around;
  }
  .mat-mdc-table[matTableResponsive] .mat-mdc-header-row th.mat-mdc-header-cell > .mat-sort-header-container {
    padding-left: 15px;
  }
  .mat-mdc-table[matTableResponsive] .mat-mdc-header-row th.mat-mdc-header-cell:not([mat-sort-header]), .mat-mdc-table[matTableResponsive] .mat-mdc-header-row th.mat-mdc-header-cell.mat-sort-header-disabled, .mat-mdc-table[matTableResponsive] .mat-mdc-header-row th.mat-mdc-header-cell.mat-column-actions {
    display: none;
  }
  .mat-mdc-table[matTableResponsive] .mat-mdc-row {
    height: auto;
  }
  .mat-mdc-table[matTableResponsive] .mat-mdc-row td.mat-mdc-cell:not(.mat-column-actions) {
    display: grid;
    grid-template-columns: 50% 50%;
    padding: 0.5rem;
    word-break: break-word;
  }
  .mat-mdc-table[matTableResponsive] .mat-mdc-row td.mat-mdc-cell:not(.mat-column-actions) > * {
    grid-column: 2;
  }
  .mat-mdc-table[matTableResponsive] .mat-mdc-row td.mat-mdc-cell:not(.mat-column-actions):before {
    content: attr(data-column-name);
    text-align: left;
  }
  .mat-mdc-table[matTableResponsive] .mat-mdc-row td.mat-column-actions {
    display: flex;
    justify-content: center;
    padding: 0 0.5rem;
  }
  .mat-mdc-table[matTableResponsive] .mat-mdc-row td.mat-column-actions > button {
    margin: 0.5rem 0;
  }
  .mat-mdc-table[matTableResponsive] .mat-mdc-row:last-of-type td:last-of-type {
    border: none;
  }
  .mat-mdc-table[matTableResponsive] .mat-mdc-row:not(:last-of-type) td:last-of-type {
    border-bottom: 0.5rem solid #fafafa;
  }
  .mat-mdc-card .mat-mdc-table[matTableResponsive],
  .mat-expansion-panel .mat-mdc-table[matTableResponsive] {
    border: 0.5rem solid #fafafa;
  }
}
@media (max-width: 960px) {
  .mat-mdc-table[matTableResponsiveLarge] {
    border-spacing: 2px;
  }
  .mat-mdc-table[matTableResponsiveLarge] .mat-mdc-header-row {
    display: flex;
    flex-wrap: wrap;
    height: auto;
    position: sticky;
    top: 0;
    z-index: 2;
  }
  .mat-mdc-table[matTableResponsiveLarge] .mat-mdc-header-row th.mat-mdc-header-cell {
    display: flex;
    flex: 1;
    align-items: center;
    padding: 1rem 0.5rem;
    justify-content: space-around;
  }
  .mat-mdc-table[matTableResponsiveLarge] .mat-mdc-header-row th.mat-mdc-header-cell > .mat-sort-header-container {
    padding-left: 15px;
  }
  .mat-mdc-table[matTableResponsiveLarge] .mat-mdc-header-row th.mat-mdc-header-cell:not([mat-sort-header]), .mat-mdc-table[matTableResponsiveLarge] .mat-mdc-header-row th.mat-mdc-header-cell.mat-sort-header-disabled, .mat-mdc-table[matTableResponsiveLarge] .mat-mdc-header-row th.mat-mdc-header-cell.mat-column-actions {
    display: none;
  }
  .mat-mdc-table[matTableResponsiveLarge] .mat-mdc-row {
    height: auto;
  }
  .mat-mdc-table[matTableResponsiveLarge] .mat-mdc-row td.mat-mdc-cell:not(.mat-column-actions) {
    display: grid;
    grid-template-columns: 50% 50%;
    padding: 0.5rem;
    word-break: break-word;
  }
  .mat-mdc-table[matTableResponsiveLarge] .mat-mdc-row td.mat-mdc-cell:not(.mat-column-actions) > * {
    grid-column: 2;
  }
  .mat-mdc-table[matTableResponsiveLarge] .mat-mdc-row td.mat-mdc-cell:not(.mat-column-actions):before {
    content: attr(data-column-name);
    text-align: left;
  }
  .mat-mdc-table[matTableResponsiveLarge] .mat-mdc-row td.mat-column-actions {
    display: flex;
    justify-content: center;
    padding: 0 0.5rem;
  }
  .mat-mdc-table[matTableResponsiveLarge] .mat-mdc-row td.mat-column-actions > button {
    margin: 0.5rem 0;
  }
  .mat-mdc-table[matTableResponsiveLarge] .mat-mdc-row:last-of-type td:last-of-type {
    border: none;
  }
  .mat-mdc-table[matTableResponsiveLarge] .mat-mdc-row:not(:last-of-type) td:last-of-type {
    border-bottom: 0.5rem solid #fafafa;
  }
  .mat-mdc-card .mat-mdc-table[matTableResponsive],
  .mat-expansion-panel .mat-mdc-table[matTableResponsive] {
    border: 0.5rem solid #fafafa;
  }
}
@media (min-width: 960px) and (max-width: 1100px) {
  .colonne-invisible {
    display: none !important;
  }
}
html, body {
  margin: 0;
  height: 100%;
}

body {
  margin: 0;
  font-family: "Avenir-Roman", "Avenir", Roboto, "Helvetica Neue", sans-serif !important;
  background-color: var(--primary);
}

h2 {
  font-family: "Avenir-Roman", "Avenir", Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif !important;
  font-size: 24px !important;
}

h3 {
  color: #273a82;
  text-transform: uppercase;
  margin-top: 16px !important;
  font-size: 18px;
  font-weight: 500;
}

h4 {
  color: #FF4081;
  font-size: 1.2em;
  margin-top: 8px;
}

label {
  display: inline-block;
}

.subTitle {
  color: #273a82;
  text-transform: uppercase;
  margin-top: 4px !important;
  font-size: 16px;
  font-weight: 500;
}

/* Style personnalisé pour les MatDialog */
.custom-dialog-container .mat-mdc-dialog-container {
  padding: 0px !important;
}

.custom-dialog-container-pdf {
  padding: 0px !important;
  width: 100%;
  height: 100%;
  max-height: 90vh;
}

table {
  box-shadow: 0px 0px;
}

.tableDossier {
  width: 100%;
}

.table-primary {
  width: 100%;
  border-collapse: separate;
}

.premiere-colonne {
  border-radius: 4px 0 0 0px;
}

.derniere-colonne {
  border-radius: 0 4px 0px 0;
}

.bouton-colonne {
  color: #273a82;
  font-weight: bold;
}

.colonne-immat {
  color: #273a82;
  font-family: "Share Tech Mono", monospace;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}

.colonne-reference {
  color: #273a82;
}

.button-standard {
  width: 128px;
  min-width: 128px;
}

.button-accent {
  border-radius: 4px;
  border-color: #FF4081;
  background-color: #FF4081;
  color: rgb(232, 232, 232);
  font-size: 14px;
  height: 40px;
}

.button-link {
  color: #273a82;
}

.button-link:hover {
  color: #FF4081;
}

/* TODO(mdc-migration): The following rule targets internal classes of input that may no longer apply for the MDC version. */
mat-input-element:disabled, .mat-mdc-input-element:disabled {
  color: #646464 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
.mat-mdc-card-title {
  font-size: 24px !important;
  font-weight: 500 !important;
  color: #273a82 !important;
  margin-bottom: 10px;
}

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
.mat-mdc-card {
  padding: 10px;
  margin: 0 auto;
  max-width: 1200px !important;
  min-width: 300px !important;
  margin-top: 16px;
  margin-bottom: 16px;
  color: rgb(39, 58, 130);
}

.mat-card-content {
  margin-top: 20px;
}

.mat-action-row {
  padding: 16px 8px 16px 8px !important;
}

mat-expansion-panel {
  background-color: #fbfbfb !important;
}

mat-expansion-panel-header {
  background-color: #fff;
}

.mat-mdc-tab {
  background-color: #fff;
}

.mat-ink-bar {
  background-color: #f44336 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
.mat-card-no-shadow {
  background-color: transparent;
  box-shadow: 0px 0px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
.mat-card-warn {
  border: 1px solid #f44336;
}

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
.mat-card-primary {
  border: 1px solid #273a82;
  border-radius: 4px;
}

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
.mat-card-accent {
  border: 1px solid #FF4081;
  border-radius: 4px;
}

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
.mat-card-alert {
  border: 1px solid #e48213;
  border-radius: 4px;
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
mat-radio-group {
  display: flex;
  flex-direction: row;
}

.RouterOutlet {
  padding-top: 4rem;
}

/* PERSONNALISE SNACKBAR */
.green-snackbar {
  --mdc-snackbar-container-color: #1F9727;
  --mat-mdc-snack-bar-button-color: white;
}
.green-snackbar .mat-simple-snackbar-action {
  color: white;
}

.blue-snackbar {
  --mdc-snackbar-container-color: #314591;
  --mat-mdc-snack-bar-button-color: white;
}
.blue-snackbar .mat-simple-snackbar-action {
  color: white;
}

.red-snackbar {
  --mdc-snackbar-container-color: #f44336;
  --mat-mdc-snack-bar-button-color: white;
}
.red-snackbar .mat-simple-snackbar-action {
  color: white;
}

/* BUTTON ET SPINNER */
.div-center {
  margin: 0 auto;
}

.div-button-left {
  display: flex;
  justify-content: flex-start;
}

.div-button-right {
  display: flex;
  justify-content: flex-end;
}

.div-button-center {
  display: flex;
  justify-content: center;
}

.div-padding {
  padding: 16px;
}

.div-container {
  display: flex;
  align-items: baseline;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
}

.div-container-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  gap: 16px;
}

.div-container-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;
  gap: 16px;
}

.div-container-between {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.div-container-around {
  width: 100%;
  display: flex;
  justify-content: space-around;
  gap: 16px;
}

.div-container-evenly {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  gap: 16px;
}

.espace-vide {
  width: 0%;
  height: 0px;
}

.openUpStepper ::ng-deep .mat-vertical-stepper-content {
  visibility: visible !important;
  height: 100% !important;
}

.button-container {
  position: relative;
  display: flex;
  justify-content: center;
}

.spinner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  z-index: 10;
  background-color: #fff;
  border-radius: 6px;
  opacity: 0.7;
}

.tckEnCours {
  color: #04970E;
}

.tckNouveau {
  color: #2C95A4;
}

.tckCloture {
  color: #f44336;
}

.label-h1 {
  font-size: 1.8em;
}

.label-error {
  font-size: 10px;
  color: #f44336;
}

.label-alert {
  color: #e48213 !important;
  font-style: italic;
}

.mat-step-text-label {
  font-size: 1.6em !important;
  color: #273a82 !important;
  text-transform: uppercase !important;
}

.label-primary {
  color: #273a82 !important;
}

.label-accent {
  color: #FF4081 !important;
}

.label-indication {
  font-size: 0.9em !important;
  color: #646464 !important;
}

.titre {
  font-size: 24px;
}

.span-primary {
  background-color: #273a82;
  color: rgb(232, 232, 232);
  border-radius: 3px;
  padding: 0px 4px 0px 4px;
  margin-right: 4px;
}

.span-spacer {
  flex: 1 1 auto;
}

.icon-display {
  transform: scale(2);
}

.icon-display-32 {
  transform: scale(1.5);
  padding: 0px 8px 0px 8px;
}

.openUpStepper ::ng-deep .mat-vertical-stepper-content {
  visibility: visible !important;
  height: 100% !important;
}

@font-face {
  font-family: "milestone one";
  src: url("assets/milestone one.otf");
}
@media only screen and (max-width: 600px) {
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
  mat-radio-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .div-container-between {
    justify-content: center !important;
    flex-wrap: wrap;
    gap: 8px;
  }
  .div-container-between div button {
    width: 100%;
    height: 48px;
  }
  .div-container-left {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
  }
  .div-container-right {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
  }
  .espace-vide {
    width: 100%;
    height: 8px;
  }
  mat-stepper {
    margin: 0 0 0 -24px;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
  mat-radio-button p {
    width: 280px;
    white-space: pre-line;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
  mat-radio-button div {
    padding: 16px;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
  mat-radio-button li {
    padding: 16px;
    white-space: pre-line;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  .mat-button-wrapper {
    padding: 0px;
  }
  .fullWidthOnPhone {
    width: 100% !important;
  }
  .mat-divider {
    display: none;
  }
  .legend-items {
    white-space: none;
    padding: 16px;
  }
  .advanced-pie-legend {
    width: 100% !important;
  }
  .advanced-pie-legend-wrapper {
    width: 100% !important;
    display: block !important;
    height: auto;
  }
  .item-value {
    font-size: 1.2em !important;
  }
  .item-percent {
    font-size: 0.9em !important;
  }
  .mat-step-text-label {
    font-size: 1em !important;
    color: #273a82 !important;
    text-transform: uppercase !important;
  }
  .input-upper {
    text-transform: uppercase;
    background-color: blue;
  }
}
.linked {
  color: #273a82;
}

.linked:hover {
  text-decoration: underline;
  cursor: pointer;
}

.matFormCP {
  max-width: 100px;
}

.formDate {
  width: 146px;
}

.tabretrait {
  margin-top: -20px;
  margin-left: -20px;
}

.filesection {
  border-left: 0.1rem solid grey;
  margin-top: 30px;
  display: flex;
}

.file {
  color: royalblue;
  cursor: pointer;
}

.file:hover {
  text-decoration: underline;
}

.content {
  border: 2px solid #273a82;
  border-radius: 10px;
  border-top: 30px solid #273a82;
  padding: 10px;
  margin-top: 20px;
  padding-bottom: 20px;
}

.fitContent {
  max-width: fit-content;
}

.titlebox {
  float: left;
  padding: 0 0px;
  margin: -35px 0 0 30px;
  background: #273a82;
  color: white;
  font-size: 1.2em;
}

.legend {
  color: #273a82;
}

.cadre {
  background-color: #ffffff;
  margin: 15px auto 15px auto;
  padding: 5px;
  border: 2px solid #273a82;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

mat-card-actions button {
  margin-left: 10px;
}

.mdc-text-field__input::-webkit-calendar-picker-indicator {
  cursor: pointer;
  display: block !important;
}

mat-form-field {
  width: 100%;
  min-width: 100px;
  max-width: 300px;
  margin: 7px;
}

mat-form-field:not(.textarea) .mat-mdc-text-field-wrapper {
  height: 40px !important;
}
mat-form-field:not(.textarea) .mat-mdc-form-field-flex {
  margin-top: -7px !important;
}

.mat-Suffix {
  margin-right: 10px;
  color: #273a82;
}

.pourcentage {
  max-width: 100px;
}

.entite {
  display: flex;
  margin: 10px;
  color: #273a82;
  align-items: center;
}

.db-b {
  border: solid blue;
}

.db-r {
  border: solid red;
}

.mat-mdc-chip {
  height: auto !important;
}