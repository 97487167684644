/* You can add global styles to this file, and also import other style files */
@use "@material/floating-label/mdc-floating-label";
@use "@material/line-ripple/mdc-line-ripple";
@use "@material/notched-outline/mdc-notched-outline";

:root{
  --primary: #273a82;
}

$background-color-body: var(--primary);

$color-primary  : #273a82;
$color-accent   : #FF4081;
$color-warn     : #f44336;
$color-basic    : #323232;

$color-alert    : #e48213;
$color-grey     : #646464;
$color-succes   : #34CD3E;
$color-done     : #04970E;
$color-sky      : #2C95A4;

$bgcolor        : rgb(232,232,232);
$bgcolor-light  : #fbfbfb;

$breakpointPhone : 600px;

// @import '/node_modules/@angular/material/theming';
// $custom-typography: mat-typography-config($font-family: 'Avenir');
// @include angular-material-typography($custom-typography);



@font-face {
  font-family: 'Avenir';
  src: local('./assets/fonts/Avenir Roman'), 
      local('Avenir-Roman'),
      url('./assets/fonts/Avenir-Roman.woff2') format('woff2'),
      url('./assets/fonts/Avenir-Roman.woff') format('woff'),
      url('./assets/fonts/Avenir-Roman.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap');
@import './app/directive/mat-table-responsive/mat-table-responsive.directive';
@import './app/directive/mat-table-responsive-large/mat-table-responsive-large.directive';

// standard HTML

html, body {
  margin:0;
  height:100%;
}

body {
  margin:0;
  font-family: 'Avenir-Roman', 'Avenir', Roboto, "Helvetica Neue", sans-serif !important;
  background-color: $background-color-body;
}

h2 {
  font-family: 'Avenir-Roman', 'Avenir', Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif !important;
  font-size: 24px !important;
}
h3 {
  color: $color-primary;
  text-transform: uppercase;
  margin-top: 16px!important;
  font-size: 18px;
  font-weight: 500;
}
h4 {
  color: $color-accent;
  font-size: 1.2em;
  margin-top: 8px;;
}

label {
  display: inline-block;
}

.subTitle {
  color: $color-primary;
  text-transform: uppercase;
  margin-top: 4px!important;
  font-size: 16px;
  font-weight: 500;
}


/* Style personnalisé pour les MatDialog */
.custom-dialog-container .mat-mdc-dialog-container {
  padding: 0px !important;  
}

.custom-dialog-container-pdf {
  padding: 0px !important;
  width:100%;
  height:100%;
  max-height:90vh;
}

table {
  box-shadow: 0px 0px;
}
.tableDossier {
  width: 100%
}
.table-primary {
  width: 100%;  
  border-collapse: separate;
}

.premiere-colonne {
  border-radius: 4px 0 0 0px;
}
.derniere-colonne {
  border-radius:  0 4px 0px 0;
}
.bouton-colonne {
  color: $color-primary;
  font-weight: bold;
}
.colonne-immat {
  color: $color-primary;
  font-family: 'Share Tech Mono', monospace;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}
.colonne-reference {
  color: $color-primary;
}

// Désactivé pour redonner une bonne apparence aux mat-toogle-slide:
// button {
//   font-family: 'Avenir-Roman', 'Avenir', Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif !important; 
//   overflow: hidden !important;
// }


.button-standard {
  width: 128px;
  min-width: 128px;
}
.button-accent {
  border-radius: 4px;
  border-color: $color-accent;
  background-color: $color-accent;
  color:$bgcolor ;
  font-size: 14px;
  height: 40px;
}
.button-link {
  color: $color-primary
}
.button-link:hover {
  color: $color-accent
}



/* TODO(mdc-migration): The following rule targets internal classes of input that may no longer apply for the MDC version. */
mat-input-element:disabled, .mat-mdc-input-element:disabled {
  color: $color-grey!important;
}
// ***** SPECIFIC IHM MATERIAL ***** //
/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
.mat-mdc-card-title{
  font-size: 24px!important;
  font-weight: 500!important;
  color: #273a82!important;
  margin-bottom: 10px;
}
/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
.mat-mdc-card {
  padding:10px;
  margin: 0 auto;
  max-width: 1200px!important;
  min-width: 300px!important;
  margin-top: 16px; 
  margin-bottom: 16px;
  color: rgb(39,58,130);
}

.mat-card-content{
  margin-top: 20px;
  // width: 100%;
  // max-width: 1200px!important;
  // min-width: 300px!important;
  
}

.mat-action-row {
  padding: 16px 8px 16px 8px!important;
}

mat-expansion-panel {
  background-color: $bgcolor-light !important;
}

mat-expansion-panel-header {
  background-color: #fff;
}


.mat-mdc-tab {
  background-color: #fff;
  
}
.mat-ink-bar {
  background-color: #f44336 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
.mat-card-no-shadow {
  background-color: transparent;
  box-shadow: 0px 0px!important;
}

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
.mat-card-warn {
  border: 1px solid $color-warn;
}


/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
.mat-card-primary {
  border: 1px solid $color-primary;
  border-radius: 4px;
}
/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
.mat-card-accent {
  border: 1px solid $color-accent;
  border-radius: 4px;
}
/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
.mat-card-alert {
  border: 1px solid $color-alert;
  border-radius: 4px;
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
mat-radio-group {
  display: flex; flex-direction: row;
}

.RouterOutlet {
  padding-top: 4rem;
}

/* PERSONNALISE SNACKBAR */
.green-snackbar {
  .mat-simple-snackbar-action {
    color: white;
  }
  --mdc-snackbar-container-color:  #1F9727;
  --mat-mdc-snack-bar-button-color: white;
}

.blue-snackbar {
  .mat-simple-snackbar-action {
    color: white;
  }
  --mdc-snackbar-container-color:  #314591;
  --mat-mdc-snack-bar-button-color: white;
}

.red-snackbar {
  .mat-simple-snackbar-action {
    color: white;
  }
  --mdc-snackbar-container-color: #f44336;
  --mat-mdc-snack-bar-button-color: white;
}



/* BUTTON ET SPINNER */
.div-center {
  margin: 0 auto;
}
.div-button-left {
  display: flex;
  justify-content: flex-start;    
}
.div-button-right {
  display: flex;
  justify-content: flex-end;    
}
.div-button-center {
  display: flex;
  justify-content: center; 
}
.div-padding {
  padding: 16px;
}
.div-container {
  display: flex;
  // align-items: center;
  align-items: baseline;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
}
.div-container-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  gap: 16px;
}
.div-container-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;
  gap: 16px;
}
.div-container-between {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 16px
}
.div-container-around {
  width: 100%;
  display: flex;
  justify-content: space-around;
  gap: 16px
}
.div-container-evenly {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  gap: 16px
}
.espace-vide {
  width: 0%;
  height: 0px;
}

.openUpStepper{
  ::ng-deep .mat-vertical-stepper-content { 
    visibility: visible !important; 
    height: 100% !important; 
  }
}

.button-container {
  position: relative;
  display: flex;
  justify-content: center;  
} 

.spinner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px; 
  z-index: 10;
  background-color: #fff;
  border-radius: 6px;
  opacity: 0.7;
}

//TICKET
.tckEnCours{
  color: #04970E;
}
.tckNouveau{
  color:#2C95A4;
}
.tckCloture{
  color: $color-warn;
}

// ***** SPECIFIC IHM FFEA Extranet ***** //
.label-h1 {
  font-size: 1.8em;
}
.label-error {
  font-size: 10px;
  color: $color-warn;
}
.label-alert {
  color: $color-alert !important;
  font-style: italic;
}
.mat-step-text-label {
  font-size: 1.6em !important;
  color: $color-primary !important;
  text-transform: uppercase !important;
}
.label-primary {
  color:$color-primary !important;
}
.label-accent {
  color:$color-accent !important;
}
.label-indication {
  font-size: 0.9em !important;
  color: $color-grey !important;
}
.titre {
  font-size: 24px;
}

.span-primary {
  background-color: $color-primary;
  color: $bgcolor;
  border-radius: 3px;
  padding: 0px 4px 0px 4px;
  margin-right: 4px;
}

.span-spacer {
  flex: 1 1 auto;
}

// mat-icon {
//   padding: 0px 8px 0px 8px;
// }
.icon-display {
  transform: scale(2);
}
.icon-display-32 {
  transform: scale(1.5);
  padding: 0px 8px 0px 8px;
}

// Permet au balise mat-stepper d'être totalement ouverte
.openUpStepper{
  ::ng-deep .mat-vertical-stepper-content { 
    visibility: visible !important; 
    height: 100% !important; 
  }
}

@font-face {
  font-family: 'milestone one';
  src: url('assets/milestone one.otf')
}

@media only screen and (max-width: $breakpointPhone) {
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
  mat-radio-group {
    display: flex; flex-direction: column;
    gap: 8px;
  }

  // Affichage des des boutons en colonnes
  .div-container-between {
    justify-content: center!important;    
    flex-wrap: wrap;
    gap: 8px;
  }
  
  // On fixe alors les largeurs de bouton pour tous les button compris dans la div-container-between
  .div-container-between div button {
    width: 100%;
    height: 48px;
  }
  
  .div-container-left {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
  }
  
  .div-container-right {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
  }

  // permet de forcer la séparation entre 2 button dans un espace flex. Exemple : page MesCommandes
  .espace-vide {
    width: 100%;
    height: 8px;
  }

  // Permet de décaler vers la gauche les stepper (en mode mobile)
  mat-stepper {
    margin: 0 0 0 -24px;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
  mat-radio-button p {
    width: 280px;
    white-space:pre-line
  }
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
  mat-radio-button div {
    padding: 16px;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
  mat-radio-button li {
    padding: 16px;
    white-space:pre-line;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  .mat-button-wrapper {
    padding: 0px;
  }

  .fullWidthOnPhone {
    width: 100% !important;
  }

  // Ne pas faire apparaitre la séparation verticale dans le total des commandes.
  .mat-divider {
    display: none;
  }

  // Charts concentrateur
  .legend-items {
    white-space: none;
    padding: 16px;
  }
  .advanced-pie-legend {
    width: 100%!important;
  }
  .advanced-pie-legend-wrapper {
    width: 100%!important;
    display: block !important;
    height: auto;
  }
  .item-value {
    font-size: 1.2em!important;
  }
  .item-percent {
    font-size: 0.9em!important;
  }
  .mat-step-text-label {
    font-size: 1em !important;
    color: $color-primary !important;
    text-transform: uppercase !important;
  }

 .input-upper{
  text-transform :uppercase;
  background-color: blue;
  
 }
}

.linked{
  color: $color-primary;
}

.linked:hover{
  text-decoration: underline;
  cursor: pointer;
}

.matFormCP{
  max-width: 100px;
}

.formDate{
  width: 146px;
}

.tabretrait{
  margin-top: -20px;
  margin-left: -20px;
}

.filesection{
  border-left:0.1rem solid grey;
  margin-top: 30px;
  display: flex;
}
.file{
  color: royalblue;
  cursor: pointer;
}
.file:hover{
  text-decoration: underline;
}

.content {
  border: 2px solid #273a82;
  border-radius: 10px;
  border-top: 30px solid #273a82;
  padding: 10px;
  margin-top: 20px;
  padding-bottom: 20px;
}
.fitContent{
  max-width:fit-content;
}
.titlebox {
  float: left;
  padding: 0 0px;
  margin: -35px 0 0 30px;
  background: #273a82;
  color: white;
  font-size: 1.2em;
}

.legend{
  color:#273a82;
}
.cadre {
  background-color: #ffffff;
  margin: 15px auto 15px auto;
  padding: 5px ;
  border: 2px solid #273a82;

  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  
}

mat-card-actions button{
  margin-left: 10px;
}

.mdc-text-field__input::-webkit-calendar-picker-indicator {
  cursor: pointer;
  display: block !important;
}

//GESTION DES MAT-FORM-FIELDS :
mat-form-field{
  width: 100%;
  min-width: 100px;
  max-width: 300px;
  margin: 7px;
}
mat-form-field:not(.textarea){
  .mat-mdc-text-field-wrapper{
    height: 40px !important;
  }
  .mat-mdc-form-field-flex{
    margin-top: -7px !important;
  }

}
// FIN DE GESTION DES MAT-FORM-FIELDS

.mat-Suffix{
  margin-right: 10px;
  color: #273a82;
}

.pourcentage{
  max-width: 100px;
}

.entite{
  display: flex;
  margin: 10px;
  color:#273a82;
  align-items: center;
  
}

 // class pour le débugage::after
 .db-b{
  border:solid blue;
}

.db-r{
  border:solid red;
}

.mat-mdc-chip {    
  height: auto !important;
}
